i.unit-icon {
  background: url(../images/army/CommonAssetsEdited.png);
  width: 18px;
  height: 20px;
  display: inline-block;
  transform: translateY(5px);

  &.carrier {
    background-position: -1660px -257px;
    height: 21px;
  }

  &.warrior {
    background-position: -1022px -3px;
    width: 16px;
  }

  &.rider {
    background-position: -1660px -293px;
    height: 22px;
  }

  &.flying {
    background-position: -283px -53px;
    width: 21px;
    height: 21px;
  }

  &.ranged {
    background-position: -1505px -14px;
    width: 22px;
    height: 21px;
  }

  &.healer {
    background-position: -1660px -236px;
    height: 19px;
  }

  &.mercenary {
    background-position: -1660px -317px;
    width: 21px;
  }

  &.mage {
    background-position: -1664px -416px;
    width: 21px;
  }
}


i.utility-icon {
  background: url(../images/army/CommonAssetsEdited.png) ;
  margin-left: 0;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  display: inline-block;
  transform: translateY(5px);

  &.atk {
    background-position: -1692px -697px;
  }

  &.def {
    background-position: -1680px -518px;
  }

  &.hp {
    background-position: -1680px -496px;
  }

  &.predator {
    background-position: -1688px -127px;
    min-width: 23px;
    width: 23px;
  }

  &.prey {
    background-position: -1688px -105px;
    min-width: 23px;
    width: 23px;
  }
}