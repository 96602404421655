//.unit_info {
//  text-align: left;
//}

.tippy-box[data-theme~='army-unit'] {
  border: 1px solid white;
  box-shadow: 5px 5px 26px 1px #000000;
}


.tippy-box {
  border-radius: 10px !important;
  opacity: .9;
}

.unit_info {
  p {
    margin-bottom: 0;

    &.extra {
      margin-top: 5px;
    }
  }

  .bonus {
    &.positive {
      color: #1fd308;
    }

    &.negative {
      color: #ed0b0f;
    }

    &.limit {
      color: #ffaa00 !important;
    }
  }

  i {
    margin-right: 5px;
  }
}