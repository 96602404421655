body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  user-select: none;
  image-rendering: pixelated;

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #c2bfea;
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d4a02d;
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ffb91b;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.custom-select {
  width: fit-content;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
}

.dialog {
  content: "";
  box-sizing: border-box;
  border-width: 110px 126px 82px 126px;
  border-style: solid;
  border-color: transparent;
  border-image: url(./images/shared/dialog/dialog.png);
  border-image-slice: 110 126 82 126 fill;
  border-image-repeat: stretch;
  background-color: transparent;
}

.box {
  border-style: solid;
  border-color: transparent;
  border-width: 4px;
  border-image: url(./images/shared/style/box.png);
  border-image-slice: 4 4 4 4 fill;
  border-image-repeat: stretch;
}

.armies_box {
  width: 720px;
  margin-left: -85px;
  margin-top: -55px;
  background: #f2eee2;
}

.calculator_border {
  margin: 40px auto 0;
  width: 805px;
}

.armies_inputs {
  .units {
    background: url(./images/shared/army/army_slots.png) no-repeat;
  }

  .attackers-side {
    .army {
      background-color: #aaffaa;
    }

    .add_army {
      background-color: #aaffaa;
    }
  }

  .defenders-side {
    .army {
      background-color: #ffaaaa;
    }

    .add_army {
      background-color: #ffaaaa;
    }
  }
}


.fight-button {
  margin-top: 20px;
  margin-bottom: -33px;
}


.army {
  padding: 5px;
  margin-bottom: 10px;
}

.add_army {
  margin-top: -8px;
}

.clear_army button {
  width: 46px;
  height: 30px;
  border: none;
  vertical-align: bottom;
  background: url(./images/shared/calc/clear_calculator_button.png) no-repeat center;
}

.save_army, .load_army {
  padding-left: 5px;

  button {
    font-size: 12px;
    background: url(./images/army/CommonAssetsEdited.png) -96px -1px;
    width: 33px;
    height: 22px;
    border: none;
    outline: none;
  }
}

.load_army button {
  background: url(./images/army/CommonAssetsEdited.png) -1230px -303px;
}

.army_units {
  margin-left: 0;
  margin-top: -15px;
}

.hero_border {
  width: 100px;
  height: 120px;
  background: url(./images/shared/heroes/hero_border.png) no-repeat;
  z-index: 1;

  padding: 1px 0 0 7px;


  .hero_background {
    width: 83px;
    height: 99px;

    &.no_hero_background {
      &-elf {
        background: url(images/army/ElfCards.png) -96px -268px;
      }

      &-human {
        background: url(images/army/HumanCards.png) -96px -268px;
      }

      &-demon {
        background: url(images/army/DemonCards.png) -96px -268px;
      }

      &-drow {
        background: url(images/army/DrowCards.png) -96px -268px;
      }

      &-undead {
        background: url(images/army/UndeadCards.png) -96px -268px;
      }

      &-monster, &-clan_castle {
        background: url(images/army/MonsterCards.png) 0px -168px;
      }
    }

    &.hero_background {
      &-elf, &-human, &-demon, &-drow, &-undead, &-monster, &-clan_castle {
        background-image: url(./images/shared/heroes/CommonHeroAssets.png);
      }

      &-elf {
        background-position: -252px -930px;
      }

      &-human, &-monster, &-clan_castle {
        background-position: -336px -930px;
      }

      &-demon {
        background-position: 0px -930px;
      }

      &-drow {
        background-position: -84px -930px;
      }

      &-undead {
        background-position: -168px -930px;
      }
    }

    .hero_image {
      background-image: url(./images/shared/heroes/CommonHeroAssets.png);
    }

    .hero_image.no_hero {
      background-image: none;
    }
  }
}

.hero_image {
  width: 82px;
  height: 99px;
  margin: 9px 0 0 0;
}

.select-hero-button {
  width: 23px;
  height: 22px;
  border: none;
  background: url(./images/shared/heroes/hero_button_right.png) no-repeat center;
  margin-left: 70px;
  margin-top: 87px;
  z-index: 3;
}

.select-hero-button:hover {
  filter: brightness(130%);
}

.units {
  width: 623px;
  height: 111px;
  margin-left: -10px;
  margin-top: 11px;
  padding-left: 4px;
  z-index: 2;
}

.unit_border {
  width: 73px;
  height: 102px;
  margin-left: 1px;
  padding-left: 10px;
  position: relative;
  font-size: 12px;

  &.lvl4-elf {
    background: url(images/army/ElfCards.png) -1px -42px;
  }

  &.lvl4-human {
    background: url(images/army/HumanCards.png) -1px -42px;
  }

  &.lvl4-demon {
    background: url(images/army/DemonCards.png) -1px -42px;
  }

  &.lvl4-drow {
    background: url(images/army/DrowCards.png) -1px -42px;
  }

  &.lvl4-undead {
    background: url(images/army/UndeadCards.png) -1px -42px;
  }

  &.lvl4-neutral {
    background: url(images/army/HumanCards.png) -1px -42px;
  }

  &.lvl4-monster {
    background: none;
  }

  input {
    width: 90%;
    margin: auto auto 0;
    position: absolute;
    height: 18px;
    line-height: 14px;
    left: 7%;
    bottom: 7px;
    z-index: -2;
    border: none;
    border-radius: 3px;
    padding: 0 3px;

    &:focus-visible {
      outline: none;
    }
  }

  p {
    position: absolute;
    font-weight: bold;
    color: white;
    right: 4px;
    bottom: -8px;
    text-shadow: 0 0 3px #000000, 0 0 3px #000000, 0 0 3px #000000, 0 0 3px #000000, 0 0 3px #000000, 0 0 3px #000000;

    &.initial_amount {
      bottom: 24px;
    }

    &.lost_amount {
      bottom: 8px;
      color: #ff8888;
    }

    &.gained_amount {
      bottom: 8px;
      color: #88aaff;
    }
  }
}

.unit {
  width: 70px;
  margin: 14px -7px 0;
  height: 82px;
  position: relative;
  z-index: -1;

  &.unit-elf {
    background: url(images/army/ElfCards.png);
  }

  &.unit-human {
    background: url(images/army/HumanCards.png);
  }

  &.unit-demon {
    background: url(images/army/DemonCards.png);
  }

  &.unit-drow {
    background: url(images/army/DrowCards.png);
  }

  &.unit-undead {
    background: url(images/army/UndeadCards.png);
  }

  &.unit-neutral {
    background: url(images/army/HumanCards.png);
  }

  &.unit-monster {
    background: url(images/army/MonsterCards.png);
  }

  &.unit-clan_castle {
    background: url(images/army/MonsterCards.png);
  }
}

.remove_army {
  width: 13px;
  height: 13px;
  margin-left: 7px;
  margin-right: 10px;
  background-image: url(./images/shared/calc/btn_remove.png);
}

.remove_army:hover {
  cursor: pointer;
}

.add_army {
  padding: 5px;

  .add_btn {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    display: inline-block;
    background-image: url(./images/shared/calc/btn_add.png);
  }

  button {
    border: none;
    background-color: transparent;
  }
}

.army_settings {
  width: 100%;
  display: inline-flex;
  margin-bottom: 5px;

  div {
    display: inline-block;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .army_side {
    width: 100px;
    text-align: center;
    vertical-align: top;
  }

  .army_race {
    margin-left: 5px;
    width: 100px;
    display: inline;
  }

  .army_options_checkboxes {
    margin-left: 10px;
    width: 110px;
    text-align: left;
    padding-left: 5px;

    input[type=checkbox] {
      margin: 5px;
    }

    label {
      margin: 0;
    }
  }

  .army_all_units_level {
    width: 100px;
    text-align: center;

    p {
      font-size: 12px;
    }

    .units_levels {
      border: 1px solid black;
      border-radius: 10px;
      background-color: white;

      button {
        border: none;
        background-color: transparent;
      }

      button.selected {
        text-shadow: 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa;
      }
    }
  }

  .army_retreat {
    width: 60px;
    text-align: center;

    p {
      font-size: 12px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      width: 45px;
      margin-right: 5px;
      border-radius: 6px;
      border-width: 1px;
      padding: 2px 5px;
      -moz-appearance: textfield;
    }
  }

  .army_fortune {
    width: 90px;
    text-align: center;

    p {
      font-size: 12px;
    }

    select.form-control {
      width: 75px;
      padding: 1px 6px;
      margin: 0 auto;
      border-radius: 6px;
      //border-width: 1px;
      border: 1px solid black;
    }
  }
}

.battlefield_setup {
  background-color: #ddffde;
  padding: 5px;
  display: inline-flex;
  width: 100%;

  .spec_defender {
    background-image: url(./images/shared/calc/spec_defender.png);
    width: 97px;
    height: 101px;
    position: relative;

    .spec_level {
      width: 90px;
      font-size: 12px;
      position: absolute;
      top: 70px;
      left: 3px;
      background-color: white;
      border: 1px solid black;
      border-radius: 5px;
      //padding: 3px;

      button {
        border: none;
        background-color: transparent;
        height: 100%;
        vertical-align: top;
        padding: 1px 7px;
      }

      button.selected {
        text-shadow: 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa;
      }
    }

  }


  .right {
    margin-left: 20px;

    .top {
      display: inline-flex;
      height: 35px;


      .land_type {
        width: fit-content;
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;
      }

      .max_def {
        width: 150px;
      }

      .salt_lake {
        width: 150px;
      }

      .max_def, .salt_lake {
        label {
          padding-left: 10px;
        }
      }
    }


    .def_buildings {
      display: flex;


      .buildings {
        display: inline-flex;
        //margin-left: 15px;
        max-width: 390px;
      }
    }

    .add_building {
      //background-image: url(./images/shared/calc/add_tower_button.png);
      background-image: url(./images/shared/calc/btn_add.png);
      width: 13px;
      height: 13px;
      margin-top: 5px;
      margin-left: 5px;
      //width: 48px;
      //height: 53px;
      //border: none;

    }
  }
}

.def_building {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  padding-top: 5px;
  margin-left: 8px;
  border: none;
  background-color: transparent;

  .fortifications_count {
    color: black;
    font-size: 14px;
    font-weight: normal;
    text-shadow: none;
    padding-top: 4px;
  }
}

.tower {
  background-image: url(./images/shared/calc/tower.png);
  width: 29px;
  height: 38px;
}

.magic_tower {
  background-image: url(./images/shared/calc/magic_tower.png);
  width: 25px;
  height: 38px;
}

.fortification {
  background-image: url(./images/shared/calc/fortification.png);
  width: 39px;
  height: 38px;
}

.gates {
  background: url(./images/army/CommonAssetsEdited.png) -1477px -100px;
  width: 37px;
  height: 38px;
}

.fortification_select {
  border-style: solid;
  border-color: transparent;
  border-width: 4px;
  border-image: url(./images/shared/style/box.png);
  border-image-slice: 4 4 4 4 fill;
  border-image-repeat: stretch;
  padding: 5px 10px;

  .forts {
    display: flex;
    margin-left: 5px;
    margin-bottom: 5px;

    button:not(:first-of-type) {
      margin-left: 15px;
    }

    button {
      border: none;
      background-color: transparent;
    }
  }


  .selected {
    filter: drop-shadow(rgb(0, 128, 255) 0px 0px 5px) drop-shadow(rgb(0, 128, 255) 0px 0px 5px);
  }

  .disabled {
    filter: grayscale(100%);
  }

  .spec_level {
    //width: 90px;
    font-size: 12px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;

    button {
      border: none;
      background-color: transparent;
      height: 100%;
      vertical-align: top;
      padding: 1px 5px;
    }

    button.selected {
      text-shadow: 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa, 0 0 3px #aaffaa;
    }
  }
}

.hero_modal-modal {
  .modal-body, .modal-footer {
    padding: 0;
  }
}

.hero_modal {
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
}

.remove_hero button.btn {
  background: url(./images/army/CommonAssetsEdited.png) -1504px -319px;
  width: 26px;
  height: 19px;
  margin-top: -31px;
  margin-left: 65px;
  border: none;
}


.hero_modal_footer {
  padding: 20px;
  margin: 0;

  background-color: #8b5728;
  width: 100%;
  text-align: right;
}


.details-button {
  margin-top: 20px;
  margin-bottom: -33px;
}

.battle_results {
  background-color: white;

  &.extra_margin_top {
    margin-top: 53px;
  }


  .army_title {
    margin-left: 105px;
    padding-left: 15px;
    width: 120px;
  }

  .attackers-side {
    .army_title {
      background-color: #aaffaa;
    }
  }

  .defenders-side {
    .army_title {
      background-color: #ffaaaa;
    }
  }

  .army {
    margin-bottom: 0px;
    background-color: transparent;
  }
}


.detailed_results_modal {
  .modal-body {
    //max-height: calc(100% - 400px);
    max-height: 800px;
    overflow: auto;
  }

  .army {
    padding: 0 5px;
  }

  .units {
    width: 760px;
  }

  .modal-lg, .modal-xl {
    max-width: 885px;
  }
}

.detailed_results {
  .battle_stage:not(:first-of-type) {
    margin-top: 35px;

  }
}

.winner {
  width: 100%;
  text-align: center;
  font-weight: bold;
  background-color: #88ff88;
}

.loser {
  width: 100%;
  text-align: center;
  font-weight: bold;
  background-color: #ff8888;
}

.tooltip_custom {
  z-index: 9999 !important;
}

#skill_button_popover {
  font-size: 16px;

  .popover-body {
    padding: 10px;

    p {
      margin: 0;
    }
  }

}

.announcement {

  div {
    margin: -55px -85px -45px;
    max-height: 200px;
    overflow: auto;
  }

  .welcome_title {
    font-size: 22px;
    font-weight: bold;
    color: darkblue;
  }
}
