.extraSpecialization {
  padding: 2px;
  font-size: 12px;
  background-color: #E3E3E3;

  &:hover {
    cursor: pointer;
    background-color: #C3C3C3;
  }

  .name {
    display: inline-block;
    min-width: 50px;
  }

  .remove {
    background: url(../images/shared/calc/btn_remove.png) no-repeat;
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 5px;
    cursor: pointer;
  }
}
