#edit_artifact_tab {

  #artifact_long_name{
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    overflow: auto;
    margin-top: 5px;
  }

  .show_artifact{
    display: inline-flex;
    margin-top: 15px;
    padding-left: 10px;

    .checkboxes {
      margin-left: 15px;
    }
  }

}
