.hero_modal_body {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  background-color: #8b5728;

  .mb-3 {
    margin-bottom: 0 !important;
  }

  .nav-tabs {
    border-bottom: none;
    height: 33px;

    .nav-link.active, .nav-item.show .nav-link {
      background-color: transparent;
      border-color: transparent;
      filter: drop-shadow(0px 0px 5px #fd9f3b) drop-shadow(0px 0px 5px #fd9f3b);
    }

    .nav-link:hover, .nav-link:focus {
      border-color: transparent;
    }

    .nav-link {
      padding: 0;

      :hover, :focus {
        border-color: transparent;
      }

      &.disabled {
        filter: grayscale(1);
      }
    }

    .nav-item {
      border-style: solid;
      border-width: 0 10px 0 10px;
      border-image: url(../images/shared/dialog/tab_button.png);
      border-image-slice: 0 10 0 10 fill;
      padding: 6px 0 3px 0;
      width: 47px;

      button {
        margin-left: -8px;
        height: 25px;
        width: 41px;
      }


      #hero_equipment_tabs-tab-hero {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1764px -703px;
      }

      #hero_utilities_tabs-tab-skills {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1848px -1009px;
        width: 32px;
        height: 21px;
        margin-left: -3px;
        margin-top: 3px;
      }

      #hero_utilities_tabs-tab-casts {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1807px -703px;
      }

      #hero_utilities_tabs-tab-effects {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1764px -628px;
      }

      #hero_utilities_tabs-tab-artifacts {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1764px -678px;
      }

      #hero_utilities_tabs-tab-runes {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1807px -628px;
      }

      #hero_utilities_tabs-tab-hero_faces {
        background: url(../images/shared/heroes/CommonHeroAssets.png) -1807px -652px;
      }

    }
  }

  .hero_tab {
    padding: 10px;
    height: 650px;
    background-image: url(../images/shared/heroes/back_texture.jpg);
  }

  .equipment {
    width: 340px;

    .hero_panel {
      display: flex;

      .helper_buttons {
        padding-left: 10px;

        button {
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }
    }

    .hero_doll {
      width: 255px;
      height: 407px;
      margin: 0 auto;
      position: relative;


      &.hero_doll_male {
        background-image: url(../images/shared/heroes/hero_doll_back_male.jpg);
      }

      &.hero_doll_female {
        background-image: url(../images/shared/heroes/hero_doll_back_female.jpg);
      }

      .hero_artifact {
        position: absolute;

        &.back {
          top: 184px;
          left: 178px;
        }

        &.belt {
          top: 172px;
          left: 96px;
        }

        &.footwear {
          top: 330px;
          left: 96px;
        }

        &.helmet {
          top: 14px;
          left: 96px;
        }

        &.item {
          top: 105px;
          left: 178px;
        }

        &.necklace {
          top: 65px;
          left: 15px;
        }

        &.ring {
          top: 223px;
          left: 15px;
        }

        &.shield {
          top: 264px;
          left: 178px;
        }

        &.thigh {
          top: 252px;
          left: 96px;
        }

        &.weapon {
          top: 303px;
          left: 15px;
        }

        &.wear {
          top: 94px;
          left: 96px;
        }

        &.wrist {
          top: 145px;
          left: 15px;
        }
      }


      .remove_artifact {
        position: absolute;
        width: 30px;
        border-radius: 12px;
        top: -6px;
        left: 43px;

        button.btn {
          background: url(../images/army/CommonAssetsEdited.png) -1504px -319px;
          width: 26px;
          height: 19px;
          border: none;
          cursor: pointer;
          margin-top: -9px;
          margin-left: 5px;
        }

        &.hide {
          display: none;
        }
      }
    }


  }

  .hero_utilities {
    width: 415px;

    .hero_skills_tab {
      background-image: url(../images/shared/dialog/hero_skills_tab.png);
    }

    .hero_faces {
      justify-content: space-between;
      overflow: auto;
      height: 638px;
      padding: 0 5px 5px;
      margin-top: -5px;

      .hero_border {
        width: 96px;
      }
    }
  }

  #hero_utilities_tabs-tabpane-artifacts {
    padding: 9px;
    overflow: hidden;
  }

  .hero_artifacts_tab {
    position: relative;
    height: 100%;

    .artifact_filter {
      display: inline-block;
      width: 100%;

      .position-select {
        flex-grow: 5;
      }

      .set-select {
        flex-grow: 10;
      }
    }

    .artifacts_container {
      display: flex;
      flex-wrap: wrap;
      padding-left: 5px;
      padding-top: 5px;
      margin-top: 10px;
      margin-right: -10px;
    }

    .pagination {
      margin-top: 8px;
      position: absolute;
      bottom: 0px;
      width: 100%;

      .pagination-buttons {
        width: 100%;
        justify-content: space-between;

        button {
          background-image: url(../images/army/CommonAssetsEdited.png);
          width: 21px;
          height: 18px;
          border: none;

          &:hover {
            background-color: transparent;
            filter: brightness(1.3);
          }

          &:first-of-type {
            background-position: -1694px -197px;
          }

          &:nth-of-type(2) {
            background-position: -556px 0;
          }

        }

      }

    }
  }

  .artifact_slot {
    color: red;
    background-image: url(../images/shared/heroes/HeroAssets.png);
    background-position: -1154px -180px;
    min-width: 68px;
    width: 68px;
    min-height: 68px;
    height: 68px;
    margin-left: -4px;
    margin-top: -4px;
    padding-top: 4px;
    padding-left: 4px;
  }

  .artifact_wrapper{
    width: 68px;
    height: 68px;
    margin-left: -4px;
    margin-top: -4px;
    position: relative;

    &:hover {
      .artifact_background, .artifact, .artifact_glow {
        filter: brightness(1.3);
      }
    }

    .artifact_background{
      position: absolute;
      background-image: url(../images/shared/heroes/HeroAssets.png);
      background-position: -1223px -186px;
      min-width: 61px;
      width: 61px;
      min-height: 61px;
      height: 61px;
      left: 3px;
      top: 3px;
    }

    .artifact {
      background-image: url(../images/shared/ArtifactAssets.png);
      position: absolute;
      width: 50px;
      height: 50px;
      left: 4px;
      top: 4px;

      &.grayed {
        opacity: .5;
        filter: grayscale(1);
      }


      &:hover:not(.grayed) {
        filter: brightness(1.3);
      }
    }

    .artifact_glow {
      position: absolute;
      background-image: url(../images/shared/heroes/HeroAssets.png);
      background-position: -1154px -117px;
      min-width: 62px;
      width: 62px;
      min-height: 62px;
      height: 62px;
      left: 3px;
      top: 3px;
    }

    .rune {
      position: absolute;
      background-image: url(../images/shared/ArtifactAssets.png);
      width: 14px;
      height: 14px;
      display: none;

      &.slot_0, &.slot_1{
        top: 5px;
      }

      &.slot_0, &.slot_2{
        left: 3px;
      }

      &.slot_1, &.slot_3{
        right: 5px;
      }

      &.slot_2, &.slot_3{
        bottom: 8px;
      }

      &.slot_4{
        top: 26px;
        left: 27px;
      }

      &.rune_eo {
        background-position: -1983px -1206px;
        display: block;
      }
      &.rune_f {
        background-position: -1999px -1206px;
        display: block;
      }
      &.rune_h {
        background-position: -2015px -1206px;
        display: block;
      }
      &.rune_ia {
        background-position: -1983px -1190px;
        display: block;
      }
      &.rune_r {
        background-position: -1999px -1190px;
        display: block;
      }
      &.rune_t {
        background-position: -2015px -1190px;
        display: block;
      }
      &.rune_th {
        background-position: -1983px -1174px;
        display: block;
      }
      &.rune_u {
        background-position: -1999px -1174px;
        display: block;
      }
      &.rune_x {
        background-position: -2015px -1174px;
        display: block;
      }
    }

    .upgraded {
      position: absolute;
      background-image: url(../images/shared/ArtifactAssets.png);
      background-position: -1983px -1222px;
      min-width: 46px;
      width: 46px;
      min-height: 39px;
      height: 39px;
      left: 4px;
      top: 4px;
    }

    .upgraded_animated {
      position: absolute;
      background-image: url(../images/shared/perfect_artifact_overlay_animation.png);
      min-width: 61px;
      width: 61px;
      min-height: 61px;
      height: 61px;
      left: 4px;
      top: 4px;
    }
  }

  .hero_effects_tab {
    .btn {
      padding: 6px 8px;
    }

    .effect_value {
      flex: .45;
      padding: 6px 8px;
    }

    .remove_effect {
      background: url(../images/shared/calc/btn_remove.png);
      width: 13px;
      height: 13px;
      display: inline-block;
      margin-right: 10px;
      margin-left: 5px;
      cursor: pointer;
    }

    .effects_list {
      margin-top: 10px;
      overflow: auto;
      height: 580px;
      border: 1px solid aliceblue;
      padding: 5px;
      border-radius: 8px;
      background-color: rgba(30, 30, 30, .6);
      color: white;
      font-size: 14px;

      p {
        margin-bottom: 5px;
      }

      .positive {
        color: #1fd308;
      }

      .negative {
        color: #ed0b0f;
      }
    }
  }
}
