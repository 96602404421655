.hero_skills {

  .class_skills {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }

  .specializations {
    display: flex;

    .skill_branch_button {
      display: inline;

      span {
        color: rgb(33, 37, 41);
      }

      .sk-btn {
        display: inline;
        padding: 0;
        border: none;
        background-color: transparent;
        color: mediumspringgreen;
      }
    }

    .hero_specialization {
      width: 89px;

      .specialization_name {
        height: 24px;
      }

      &.advanced_skills {
        margin-left: auto;

        .skills {
          height: 511px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    .specialization_name {
      text-align: center;
      overflow: hidden;
    }
  }
}

.skill_container {
  height: 73px;
  width: 89px;
  position: relative;

  &.disabled {
    filter: brightness(1);

    .skill_border, .skill_image {
      filter: grayscale(1) !important;
    }

    &:hover {
      .skill_border, .skill_image {
        filter: brightness(1) grayscale(1) !important;
      }
    }
  }

  &:hover {
    .skill_border, .skill_image {
      filter: brightness(1.3);
    }
  }


  .skill_border {
    background-image: url(../images/shared/SkillAssets.png);
    height: 77px;
    width: 89px;

    &.passive {
      background-position: -2px -1940px;
      min-width: 94px;
      width: 94px;
      min-height: 78px;
      height: 78px;
    }

    &.active {
      background-position: -1710px -1693px;
      min-width: 80px;
      width: 80px;
      min-height: 88px;
      height: 88px;
    }
  }

  .skill_image {
    background-image: url(../images/shared/SkillAssets.png);
    background-position: -83px -1614px;
    min-width: 80px;
    width: 80px;
    min-height: 88px;
    height: 88px;
  }

  .skill_border, .skill_image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.disabled {
      filter: grayscale(1);
    }
  }

  .skill_lvl {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 58px;
    width: 40px;
    height: 14px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: rgb(163, 163, 163);

    &.maxed {
      background-color: rgb(255, 255, 153);
    }

    .counter {
      top: -2px;
      text-align: center;
      width: 100%;
      color: black;
      font-weight: bold;
      font-size: 12px;
      position: absolute;
    }
  }
}

.extraSpecialization {
  padding: 2px;

  &:hover {
    cursor: pointer;
    background-color: #D3D3D3;
  }

  &.remove {
    color: red;
  }
}